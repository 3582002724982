<template>
<div>
    <b-modal
    id="deleteTariffSetting"
    title="Удалить настройку тарифа"
    size="sm"
    :no-close-on-backdrop="true"
    @close="closeModal"
    >
    <div>
        <label class="label1" style="color: red !important;">Предупреждение!</label>
    </div>
        <br>
    <div>
        <label class="label2">Вы действительно хотите удалить эту настройку тарифа?</label>
    </div>
        <template #modal-footer>
            <button class="btn btn-primary"
            :disabled="submitButtonDisabled"
            @click.prevent="getdeleteSetting">
                Удалить
            </button>
            <b-button
            @click.prevent="closeModal">
                Отмена
            </b-button>
        </template>
    </b-modal>
</div>
</template>
  
<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
export default {
    props: ['id', 'idModal'],
    data() {
        return {
            submitButtonDisabled: false
        }
    },
    methods: {
        getdeleteSetting() {
            this.submitButtonDisabled = true
            this.$http
                .delete(`tariff-performers/base/${this.id}/settings/${this.idModal}`)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    let id = this.id
                    this.$emit('refresh', id)
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
        },
        closeModal() {
            this.$bvModal.hide('deleteTariffSetting')
        }
    }

}
</script>

<style scoped>
.label1{
    display: flex;
    width: 100%;    
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
}
.label2{
    display: flex;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
</style>
