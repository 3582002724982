<template>
  <div>
    <b-modal
    :id="'CopyTariffSettingPerformer'"
    title="Создать настройку тарифа"
    size="sm"
    hide-footer
    mo-enforce-focus
    :no-close-on-backdrop="true"
    @show="getIDSetting"
    @close="clearSetting"
    >
    <b-row>
      <b-col>
        <div>
        <b-row>
          <b-col class="border">
            <label class="mt-1" style="font-size: 15px;">Тип заказа: <i style="color: red;">*</i> </label>
            <b-form-group style="column-count: 3; margin-top: 20px;">
              <b-form-checkbox
              v-for="option in order_types"
              :key="option.id"
              v-model="createTariffPerformer.order_type_id"
              :value="option.id"
              name="flavour-3a"
              style="height: 25px;"
              >
              {{ option.name }}
            </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="column-count: 3;" class="border mt-1 py-1">
            <label style="font-size: 11px;">Периотет: <i style="color: red;">*</i> </label>
            <b-input size="sm" type="number" v-model="createTariffPerformer.priority"/>
            <label style="font-size: 11px;">Начало действия: <i style="color: red;">*</i></label>
            <b-input size="sm" type="time" v-model="createTariffPerformer.start_time"/>
            <label style="font-size: 11px;">Начало окончание: <i style="color: red;">*</i></label>
            <b-input size="sm" type="time" v-model="createTariffPerformer.end_time"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border mt-1">
            <b-form-group style="column-count: 7; margin-top: 20px;">
              <b-form-checkbox
              v-for="option in weekdays"
              :key="option.key"
              v-model="createTariffPerformer.weekdays"
              :value="option.key"
              name="flavour-3a"
              style="height: 25px;"
              >
              {{  option.name }}
            </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="mt-1 py-1 border">
            <label>Тип тарифа заказа:</label>
            <v-select
            label="name"
            v-model="createTariffPerformer.order_tariff_id"
            :options="order_tariffs"
            class="select-size-sm"
            :reduce="item => item.id"
            ></v-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="column-count: 2;" class="mt-1 py-1 border">
              <label>Статус водителя: <i style="color: red;">*</i></label>
              <b-form-select v-model="createTariffPerformer.status_performer" :options="performer_status" value-field="id" text-field="name" size="sm"></b-form-select>
              <label>Порог цены: <i style="color: red;">*</i></label>
              <b-input style="height: 30px;" type="number"  v-model="createTariffPerformer.price_limit"/>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <label class="d-flex">
                <b-form-checkbox v-model="createTariffPerformer.write_commission_assigning_performer" id="1"></b-form-checkbox>
                <div style="user-select: none; font-size: 14px;">
                    Списывать комиссию при назначении водителя <i style="color: red;">*</i>
                </div>
            </label>
          </b-col>
        </b-row>
      </div>
      <div style="margin-top: 10px;">
        <b-row>
          <b-col>
            <label style="font-size: 12px;">Настройка списания/начисления бонусов по запросу водителя</label>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="border p-2">
            <label style="font-size: 14px;">(Из списка заказов) от ...</label>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">от цены</label>
                <div style="column-count: 2;">
                  <label>Цена от: <i style="color: red;">*</i>
                    <b-form-input v-model="by_price.price" type="number"></b-form-input>
                  </label>
                  <label>Комиссию:
                    <b-form-input v-model="by_price.percent" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_active = false" v-for="(by_price, i) in createTariffPerformer.by_price" :key="i">
                        {{ `От ${by_price.price} : ${by_price.percent}` }}
                      </div> 
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!by_price.price > 0 || !by_price.percent > 0" @click.prevent="getPush">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_active" @click="deletePrice">Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">от расстояния</label>
                <div style="column-count: 2;">
                  <label>Расстояние от:
                  <b-form-input v-model="by_distance.distance" type="number"></b-form-input>
                  </label>
                  <label>Комиссию:
                    <b-form-input v-model="by_distance.percent" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_activeDistance = false" v-for="(by_distance, i) in createTariffPerformer.by_distance" :key="i">
                        {{ `От ${by_distance.distance} : ${by_distance.percent}` }}
                      </div>
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!by_distance.distance > 0 || !by_distance.percent > 0" @click.prevent="getByDistance">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_activeDistance" @click="deleteByDistance" >Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-2 border">
                <label style="margin-top: 5px;">от длительности</label>
                <div style="column-count: 2;">
                  <label>Время (мин.) от:
                    <b-form-input v-model="by_time.time" type="number"></b-form-input>
                  </label>
                  <label>Комиссию:
                    <b-form-input v-model="by_time.percent" type="number"></b-form-input>
                  </label>
                  <b-row>
                    <div class="border ml-1" style="margin-top: 18px; margin-right: 10px; height: 100px; width: 250px;">
                      <div class="border" style="background-color: yellow;" @click.prevent="getid(i), is_activeTime = false" v-for="(by_time, i) in createTariffPerformer.by_time" :key="i">
                        {{ `От ${by_time.time} : ${by_time.percent}` }}
                      </div>
                    </div>
                  </b-row>
                </div>
                <b-col class="d-flex justify-content-end my-1">
                  <b-button variant="primary" style="width: 100px; height: 40px; margin-right: 10px;" :disabled="!by_time.time > 0 || !by_time.percent > 0" @click.prevent="getByTime">Добавить</b-button>
                  <b-button variant="secondary" style="width: 100px; height: 40xp;" :disabled="is_activeTime" @click="deleteByTime">Удалить</b-button>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      </b-col>
    </b-row>
    <b-row>
        <b-col cols="12" class="d-flex justify-content-between">
            <b-button
            :disabled="submitButtonDisabled"
            class="mt-2 col-md-5"
            variant="primary"
            @click.prevent="createTariffPerformerSetting">
                Создать
            </b-button>
            <b-button class="mt-2 col-md-5" variant="secondary"
            @click.prevent="clearSetting">
                Отмена
            </b-button>
        </b-col>
    </b-row>
    </b-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import convertSendDataJson from '@/@core/utils/convertSendData'
 
export default {
  props: ['id', 'idModal', 'order_types','performer_status', 'weekdays', 'order_tariffs'],
  components:{
    vSelect
  },
  data(){
    return {
      by_price: {
        price: null,
        percent: null,
      },
      by_distance: {
        distance: null,
        percent: null,
      },
      by_time: {
        time: null,
        percent: null,
      },
      index: null,
      is_active: true,
      is_activeDistance: true,
      is_activeTime: true,
      submitButtonDisabled: false,
      createTariffPerformer: {
        order_type_id: undefined,
        order_tariff_id: undefined,
        priority: undefined,
        start_time: undefined,
        end_time: undefined,
        weekdays: [],
        status_performer: undefined,
        price_limit: undefined,
        write_commission_assigning_performer: undefined,
        by_price: [],
        by_distance: [],
        by_time: [],
      },
    }
  },
  methods: {
    getIDSetting() {
      this.$http
          .get(`tariff-performers/base/${this.id}/settings/${this.idModal}/edit`)
          .then(res => {
            this.createTariffPerformer.order_type_id = res.data.order_type_id
            this.createTariffPerformer.order_tariff_id = res.data.order_tariff_id
            this.createTariffPerformer.priority = res.data.priority
            this.createTariffPerformer.start_time = res.data.start_time
            this.createTariffPerformer.end_time = res.data.end_time
            this.createTariffPerformer.weekdays = res.data.weekdays
            this.createTariffPerformer.status_performer = res.data.status_performer
            this.createTariffPerformer.price_limit = res.data.price_limit
            this.createTariffPerformer.write_commission_assigning_performer = res.data.write_commission_assigning_performer == 1 ? true : false;
            res.data.prices.forEach((value) => {
              this.createTariffPerformer.by_price = value.by_price
              this.createTariffPerformer.by_distance = value.by_distance
              this.createTariffPerformer.by_time = value.by_time
            })
          })
    },
    getPush() {
      this.createTariffPerformer.by_price.push(this.by_price);
      this.by_price = {
        price: null,
        percent: null,
      };
    },
    getByDistance() {
      this.createTariffPerformer.by_distance.push(this.by_distance);
      this.by_distance = {
        distance: null,
        percent: null,
      };
    },
    getByTime() {
      this.createTariffPerformer.by_time.push(this.by_time);
      this.by_time = {
        time: null,
        percent: null,
      };
    },
    getid(i) {
      this.index = i
    },
    deletePrice() {
      this.createTariffPerformer.by_price.splice(this.index, 1)
      this.index = null
      this.is_active = true
    },
    deleteByDistance() {
      this.createTariffPerformer.by_distance.splice(this.index, 1)
      this.index = null
      this.is_activeDistance = true
    },
    deleteByTime() {
      this.createTariffPerformer.by_time.splice(this.index, 1)
      this.index = null
      this.is_activeTime = true
    },
    createTariffPerformerSetting() {
      this.submitButtonDisabled = true,
      this.createTariffPerformer.write_commission_assigning_performer = this.createTariffPerformer.write_commission_assigning_performer == true ? 1 : 0;
      this.createTariffPerformer.price_limit = Number(this.createTariffPerformer.price_limit)
      this.createTariffPerformer.priority = Number(this.createTariffPerformer.priority)
      let data = JSON.parse(JSON.stringify(this.createTariffPerformer))
      convertSendDataJson(data.by_price)
      convertSendDataJson(data.by_distance)
      convertSendDataJson(data.by_time)
      this.$http
          .post(`/api/tariff-performers/base/${this.id}/settings`, this.createTariffPerformer)
          .then(res => {
            this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                      title: 'Успех!',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: res.data.message,
                  },
              })
              this.clearSetting()
              let id = this.id
              this.$emit('refresh', id)
          })
          .catch(err => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                title: 'Некорректные данные!',
                icon: 'XIcon',
                variant: 'danger',
                text: err.response.data.errors,
                },
          })
        })
        .finally(() => {
          this.submitButtonDisabled = false;
        })
    },
    clearSetting() {
      this.by_price = {
        price: null,
        percent: null,
      },
      this.by_distance = {
        distance: null,
        percent: null,
      },
      this.by_time = {
        time: null,
        percent: null,
      },
      this.index = null,
      this.is_active = true,
      this.is_activeDistance = true,
      this.is_activeTime = true,
      this.submitButtonDisabled = false,
      this.createTariffPerformer = {
        order_type_id: undefined,
        order_tariff_id: undefined,
        priority: undefined,
        start_time: undefined,
        end_time: undefined,
        weekdays: [],
        status_performer: undefined,
        price_limit: undefined,
        write_commission_assigning_performer: undefined,
        by_price: [],
        by_distance: [],
        by_time: [],
      };
      this.$bvModal.hide('CopyTariffSettingPerformer')
    },
  }
}
</script>
